import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import s from '@/styles/uiComp/catalog/LocationCate.module.scss';
import { useLocationData } from '@/hooks/admin/useLocationData';
import { useGlobalData } from '@/context/GlobalContext';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import i18n from '@/lib/i18n';

function LocationSubCate({
  selectedDistrict,
  selectedCity,
  onSelectDistrict,
  onSelectCity,
}) {
  const { fetchActiveLocations, activateLocations } = useLocationData();
  const { quantityAd } = useGlobalData();
  const router = useRouter();
  const { t } = useTranslation('');

  useEffect(() => {
    fetchActiveLocations();
  }, []);

  const generateDistrictsList = useCallback((currentCity) => currentCity.districts.map((district) => ({
    value: district.id,
    label: t(`locations:${currentCity.codename}.districts.${[district.codename]}`),
  })), [t]);

  const cities = useMemo(() => {
    const activeCities = activateLocations.map((city) => ({
      value: city.id,
      label: t(`locations:${city.codename}.${city.codename}`),
    }));

    const allcities = {
      value: 'allcities',
      label: t('locations:allcities'),
    };

    return [allcities, ...activeCities];
  }, [activateLocations, t]);
  const currentCity = useMemo(
    () => activateLocations.find((city) => String(city.id) === String(selectedCity)),
    [activateLocations, selectedCity],
  );

  const districtsList = useMemo(
    () => currentCity && generateDistrictsList(currentCity),
    [currentCity, generateDistrictsList],
  );
  const getDistrictAdCount = (districtId) => {
    const districtCount = quantityAd[0]?.counts?.district?.[districtId] || 0;
    return districtCount;
  };

  const getCityAdCount = (cityId) => {
    const cityCount = quantityAd[0]?.counts?.city?.[cityId] || 0;
    return cityCount;
  };

  const [step, setStep] = useState(currentCity ? 2 : 1);

  const handleReturnToCitiesList = () => {
    setStep(1);
    onSelectDistrict(undefined);
  };
  const handleCityChange = (id) => {
    onSelectCity(id);
  };

  useEffect(() => {
    if (currentCity) {
      setStep(2);
    } else {
      setStep(1);
    }
  }, [currentCity]);

  return (
    <div className={s.location_subcategory}>
      <h4>{i18n.createEvents.locations[router.locale]}</h4>
      <div className={s.subcategory_option}>
        {step === 2 && (
        <button
          className={s.return_to_cities_list}
          onClick={handleReturnToCitiesList}
        >
            {t('adv:backToCitiesList')}
        </button>
        )}
        {step === 1 && cities?.map((city) => (
          <div className={s.option} key={city.value}>
            <label>
              <input
                checked={currentCity?.id === city.value || (city.value === 'allcities' && selectedCity === 'allcities')}
                type="radio"
                name="city"
                onClick={() => {
                  if (currentCity?.id === city.value) {
                    setStep(2);
                  }
                }}
                onChange={() => handleCityChange(city.value)}
              />
              {city.label}
              <span>{getCityAdCount(city.value)}</span>
            </label>
          </div>
        ))}
        {step === 2 && districtsList?.map((district) => (
          <div className={s.option} key={district.value}>
            <label>
              <input
                checked={`${district.value}` == `${selectedDistrict}`}
                type="radio"
                name="district"
                onChange={() => onSelectDistrict(district.value)}
              />
              {district.label}
              <span>{getDistrictAdCount(district?.value)}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default LocationSubCate;
